import React from 'react';
import { graphql } from 'gatsby';

import * as styles from './styles/privateTours.module.scss';
import withMeta from '../hocs/withMeta';
import {
  IPageDetails,
  IPageContext,
  IPageWysiwyg,
  ITourList,
  IPageTours,
} from '../interfaces';
import {
  PrivateHero,
  TextParser,
  PrivateForm,
  ExclusiveTours,
} from '../components';
// import PageTours from '../elements/PageTours/PageTours';
import { getPageElement, scrollTo } from '../utils';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
    allContentfulTour: ITourList;
  };
  pageContext: IPageContext;
}

class PrivateTours extends React.Component<IProps> {
  enquiry: HTMLDivElement | null = null;

  render() {
    const { data, pageContext } = this.props;
    const { title, subtitle, heroImage, pageElements } = data.contentfulPage;
    // const tours = data.allContentfulTour.edges.map(tour => tour.node);

    const enquiry = getPageElement(
      pageElements,
      'ContentfulPageWysiwyg'
    ) as IPageWysiwyg;

    const exclusive = getPageElement(
      pageElements,
      'ContentfulPageTours'
    ) as IPageTours;

    return (
      <div className={styles.container}>
        <PrivateHero
          title={title}
          subtitle={subtitle || ''}
          image={heroImage}
          onNavClick={this.scrollToEnquiry}
        />
        <div className='centered-content vertical-margin'>
          {enquiry && (
            <TextParser
              className={styles.info}
              source={enquiry.text.text}
              darkMode={true}
            />
          )}
        </div>
        <ExclusiveTours
          title={exclusive.title}
          tours={exclusive.tours}
          langPath={pageContext.langPath}
        />
        {/* <PageTours
          pageTours={{ title: 'All tours', tours }}
          pageContext={pageContext}
          darkMode={true}
        /> */}
        <div ref={(elem) => (this.enquiry = elem)}>
          <PrivateForm />
        </div>
      </div>
    );
  }

  scrollToEnquiry = () => {
    scrollTo(this.enquiry);
  };
}

export const query = graphql`
  query PrivateQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
    allContentfulTour(
      filter: {
        node_locale: { eq: $locale }
        title: { ne: "ekki eyða" }
        groupSize: { eq: "private-tour" }
      }
    ) {
      ...TourList
    }
  }
`;

export default withMeta(PrivateTours);
